
<template>
  <div class="home-page">
    <p><button @click="signMessage">获取签名</button></p>
    <p><button @click="payUsdt">支付usdt</button></p>
    <p><button @click="usdtBalance">获取usdt余额</button></p>
    <p><button @click="transfer">转账eth</button></p>
    <p><button @click="loading">开启loading</button></p>
  </div>
</template>

<script>
import { verifySignature, loading } from "@/contracts";
export default {
  created() {
    // new VConsole();
    console.log('home created')
    this.getAddress();
  },
  methods: {
    async getAddress() {
      await this.$dapp.waiting();
      console.log("HomePage获取到地址：", this.$dapp.address);
    },
    async signMessage() {
      const data = {
        type: "withdraw",
        timestamp: parseInt(Date.now() / 1000),
      };
      const [err, signData] = await this.$dapp.signMessage(data);
      console.log(err, signData);
      if (err === null) {
        console.log(
          "签名结果",
          verifySignature(signData, data, this.$dapp.address)
        );
      }
    },
    async payUsdt() {
      const usdt = await this.$dapp.usdt();
      const [err, tx] = await usdt.transfer(
        "0x9EAf19ffA4Cc9E2b536068E9997B9D178353f97B",
        "1.2"
      );
      if (!err) {
        console.log(tx);
      }
      console.log(err);
    },
    async usdtBalance() {
      const usdt = await this.$dapp.usdt();
      const balance = await usdt.balanceOf();
      console.log("balance", balance);
    },
    async transfer() {
      const [err, tx] = await this.$dapp.transfer(
        "0x9EAf19ffA4Cc9E2b536068E9997B9D178353f97B",
        0.00000003
      );
      console.log(err, tx);
    },
    loading() {
      loading.show();

      setTimeout(() => {
        loading.hide();
      }, 4000);
    },
  },
};
</script>

<style scoped lang="scss">
.home-page {
  padding-top: 92px;
  min-height: 100vh;
  box-sizing: border-box;
  color: #000;
}
</style>